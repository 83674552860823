import { push } from 'connected-react-router';
import { Dispatch } from 'redux';

import { getDefaultStore } from 'jotai';
import avatarKeyHash from 'src/core/AvatarUnity/avatarKeyHash';
import { avatarUpdateAtom, avatarUpdatePreviewAtom } from '../core/atoms';
import * as A from '../types/actions';
import {
  ActionTypes,
  AgeGateStatus,
  AsyncActionTypes,
  Dialogs,
  MetricsEvents,
  ReplikaActLikeBehavior,
  Routes,
  Themes,
} from '../types/enums';
import {
  Bot,
  BotPatch,
  CoreDescription,
  RelationshipStatus,
  RelationshipStatusId,
  UserInterest,
  UserProfile,
  UserProfilePatch,
  VoiceType,
} from '../types/models';
import { DA } from '../types/redux';
import { AvatarMode } from '../types/states';
import { apiAction } from '../utils/asyncAction';
import fetchOptions from '../utils/fetchOptions';
import getWebglVersion from '../utils/getWebglVersion';
import { EUserProperties, logEvent, setUserProperties } from '../utils/metrics';
import { API_BASE_URL } from '../utils/uri';
import { queueSystemNotification, setActiveDialog } from './ui';

const WEBGL_UNAVAILABLE_MESSAGE =
  'WebGL support is disabled or unavailable in your browser';

export const getPersonalBot =
  (presignup?: boolean): DA<Bot> =>
  async (dispatch, getState) => {
    const endpoint = `${API_BASE_URL}/personal_bot`;
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetPersonalBot>(
      AsyncActionTypes.GetPersonalBot,
      dispatch,
      {},
      {
        onRequest: () => fetch(endpoint, fetchOpts),
        onSuccess: (bot) => {
          if (presignup) return;

          const state = getState();
          const store = getDefaultStore();

          if (bot.avatar_v2) {
            store.set(avatarUpdateAtom, {
              type: bot.avatar_v2.avatar_type,
              age: bot.avatar_v2.age,
              variations: bot.avatar_v2.active_variations,
              bodyType: bot.avatar_v2.body_type ?? null,
              gender: bot.avatar_v2.gender ?? null,
              roomItems: bot.room_items ?? null,
              pets: bot.pets ?? null,
            });
          }

          if (!bot.avatar_v2) {
            // if user had previous version, force avatar update
            const forceAvatarUpdate = 'avatar' in bot;

            if (
              !state.avatars.persist.chooseAvatarScreenShown ||
              forceAvatarUpdate
            ) {
              dispatch(push(Routes.ChooseAvatar));
            }
          } else if (bot.avatar_v2.active_variations.length > 0) {
            store.set(avatarUpdateAtom, {
              variations: bot.avatar_v2.active_variations,
            });
          }

          if (bot.room_items && bot.room_items.length > 0) {
            store.set(avatarUpdateAtom, { roomItems: bot.room_items });
          }
        },
      },
    );
  };

export const updateBot =
  (botPatch: BotPatch, checkHash = false): DA<Bot | undefined> =>
  async (dispatch, getState) => {
    const state = getState();
    const { bot } = state.profile.persist;

    if (!bot) {
      throw new Error('no bot');
    }

    botPatch.avatar_v2 = botPatch.avatar_v2 && {
      id: bot.avatar_v2?.id,
      type: '3d',
      ...botPatch.avatar_v2,
    };

    const params = { botPatch, botId: bot.id };

    const newBot = {
      ...bot,
      ...botPatch,
      avatar_v2: {
        ...bot.avatar_v2,
        ...botPatch.avatar_v2,
      },
    };

    const prevHash = avatarKeyHash({
      type: bot?.avatar_v2?.type ?? '',
      age: bot?.avatar_v2?.age ?? 0,
      variations: bot?.avatar_v2?.active_variations ?? [],
      bodyType: bot?.avatar_v2?.body_type ?? {},
      theme: Themes.Default,
      cameraSlot: null,
      pets: bot?.pets,
      roomItems: bot?.room_items,
    });

    const newHash = avatarKeyHash({
      type: newBot?.avatar_v2?.type ?? '',
      age: newBot?.avatar_v2?.age ?? 0,
      variations: newBot?.avatar_v2?.active_variations ?? [],
      bodyType: newBot?.avatar_v2?.body_type ?? {},
      theme: Themes.Default,
      cameraSlot: null,
      pets: newBot?.pets,
      roomItems: newBot?.room_items,
    });

    if (checkHash && prevHash === newHash) {
      return;
    }

    return apiAction<A.UpdateBot>(
      AsyncActionTypes.UpdateBot,
      dispatch,
      params,
      {
        onRequest: () => {
          if (botPatch.gender) {
            setUserProperties({
              [EUserProperties.ReplikaGender]: botPatch.gender,
            });
          }
          return fetch(
            `${API_BASE_URL}/personal_bot`,
            fetchOptions(state, 'PATCH', botPatch),
          );
        },
        onSuccess: (bot) => {
          const store = getDefaultStore();
          if (
            (botPatch.avatar_v2?.active_variations ||
              botPatch.avatar_v2?.age != null ||
              botPatch.avatar_v2?.avatar_type != null ||
              botPatch.room_items != null ||
              botPatch.pets != null) &&
            bot.avatar_v2
          ) {
            store.set(avatarUpdateAtom, {
              type: bot.avatar_v2.avatar_type,
              age: bot.avatar_v2.age,
              variations: bot.avatar_v2.active_variations,
              bodyType: bot.avatar_v2.body_type ?? null,
              gender: bot.avatar_v2.gender ?? null,
              roomItems: bot.room_items ?? null,
              pets: bot.pets ?? null,
            });

            store.set(avatarUpdatePreviewAtom, [
              ...bot.avatar_v2.active_variations,
              ...(bot.room_items ?? []),
              ...(bot.pets ?? []),
            ]);
          }
        },
      },
    );
  };

export const getUserProfile =
  (triggerAgeGate = true): DA<UserProfile> =>
  async (dispatch, getState) => {
    const state = getState();
    const { userId } = state.auth.persist;

    const endpoint = `${API_BASE_URL}/profile`;
    const fetchOpts = fetchOptions(state, 'GET', undefined, undefined, {
      bypassAgeLock: true,
    });
    const params = { userId };

    return apiAction<A.GetUserProfile>(
      AsyncActionTypes.GetUserProfile,
      dispatch,
      params,
      {
        onRequest: () => fetch(endpoint, fetchOpts),
        onSuccess: (userProfile) => {
          if (!triggerAgeGate) return;
          showAgeGateIfNeeded(userProfile, dispatch);
        },
      },
    );
  };

export const updateUserProfile =
  (
    userProfilePatch: UserProfilePatch,
    triggerAgeGate = true,
  ): DA<UserProfile> =>
  async (dispatch, getState) => {
    const state = getState();
    const { userId } = state.auth.persist;

    if (!userId) {
      throw new Error('no userid');
    }

    const params = { userProfilePatch, userId };

    return apiAction<A.UpdateUserProfile>(
      AsyncActionTypes.UpdateUserProfile,
      dispatch,
      params,
      {
        onRequest: () => {
          if (userProfilePatch.pronoun) {
            setUserProperties({
              [EUserProperties.Pronoun]: userProfilePatch.pronoun,
            });
          }

          if (userProfilePatch.age_range) {
            setUserProperties({
              [EUserProperties.AgeRange]: userProfilePatch.age_range,
            });
          }

          return fetch(
            `${API_BASE_URL}/profile`,
            fetchOptions(state, 'PATCH', userProfilePatch),
          );
        },
        onSuccess: (userProfile) => {
          if (!triggerAgeGate) return;
          showAgeGateIfNeeded(userProfile, dispatch);
        },
      },
    );
  };

export const resetServerError = () => ({
  type: ActionTypes.ResetServerError,
});

export const updatePassword =
  (oldPassword: string, newPassword: string): DA<{}> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'POST', {
      old_password: oldPassword,
      new_password: newPassword,
    });

    return apiAction<A.UpdateUserPassword>(
      AsyncActionTypes.UpdateUserPassword,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(`${API_BASE_URL}/profile/actions/update_password`, fetchOpts),
      },
    );
  };

export const addPassword =
  (newPassword: string): DA<{}> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'POST', {
      new_password: newPassword,
    });

    return apiAction<A.AddPassword>(
      AsyncActionTypes.AddPassword,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(`${API_BASE_URL}/profile/actions/add_password`, fetchOpts),
      },
    );
  };

export const setPasswordAndEmail =
  (password: string, email: string): DA<UserProfile> =>
  async (dispatch, getState) => {
    return apiAction<A.SetPasswordAndEmail>(
      AsyncActionTypes.SetPasswordAndEmail,
      dispatch,
      { email },
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/profile/actions/set_password_and_email`,
            fetchOptions(getState(), 'POST', { email, password }),
          ),
      },
    );
  };

export const updateEmail =
  (password: string, email: string): DA<{}> =>
  async (dispatch, getState) => {
    return apiAction<A.UpdateUserEmail>(
      AsyncActionTypes.UpdateUserEmail,
      dispatch,
      { email },
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/profile/actions/update_email_with_verification`,
            fetchOptions(getState(), 'POST', { email, password }),
          ),
      },
    );
  };

export const getVoices = (): DA<VoiceType[]> => async (dispatch, getState) => {
  return apiAction<A.GetVoices>(
    AsyncActionTypes.GetVoices,
    dispatch,
    {},
    {
      onRequest: () =>
        fetch(`${API_BASE_URL}/voices`, fetchOptions(getState(), 'GET')),
    },
  );
};

export const setAvatarMode = (avatarMode: AvatarMode | 'auto') => {
  const isWebGLSupported = getWebglVersion() === 2;

  if (avatarMode !== 'no3d' && !isWebGLSupported) {
    logEvent(MetricsEvents.UnityWebgUserError);
    return queueSystemNotification(WEBGL_UNAVAILABLE_MESSAGE, 'warning');
  }

  return {
    type: ActionTypes.SetAvatarMode,
    avatarMode,
  };
};

export const resetAvatarCustomization = () => {
  return {
    type: ActionTypes.ResetAvatarCustomization,
  };
};

export const getRelatioshipStatuses =
  (): DA<{ statuses: RelationshipStatus[] }> => async (dispatch, getState) => {
    return apiAction<A.GetRelatioshipStatuses>(
      AsyncActionTypes.GetRelatioshipStatuses,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/relationship_statuses`,
            fetchOptions(getState(), 'GET'),
          ),
      },
    );
  };

export const setRelationshipStatusToUpdate = (
  relationshipStatusToUpdate: RelationshipStatusId,
) => {
  return {
    type: ActionTypes.SetRelationshipStatusToUpdate,
    relationshipStatusToUpdate,
  };
};

export const getUserInterests =
  (): DA<{ interests: UserInterest[] }> => async (dispatch, getState) => {
    return apiAction<A.GetUserInterests>(
      AsyncActionTypes.GetUserInterests,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/user_interests`,
            fetchOptions(getState(), 'GET'),
          ),
      },
    );
  };

export const updateUserInterests =
  (ids: string[]): DA<{}> =>
  async (dispatch, getState) => {
    return apiAction<A.UpdateUserInterests>(
      AsyncActionTypes.UpdateUserInterests,
      dispatch,
      { ids },
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/user_interests`,
            fetchOptions(getState(), 'POST', { interests: ids }),
          ),
      },
    );
  };

export const getDialogModels = (): DA<{}> => async (dispatch, getState) => {
  return apiAction<A.GetDialogModels>(
    AsyncActionTypes.GetDialogModels,
    dispatch,
    {},
    {
      onRequest: () =>
        fetch(
          `${API_BASE_URL}/dialog_version`,
          fetchOptions(getState(), 'GET'),
        ),
    },
  );
};

export const setDialogModel =
  (id: string): DA<{}> =>
  async (dispatch, getState) => {
    return apiAction<A.SetDialogModel>(
      AsyncActionTypes.SetDialogModel,
      dispatch,
      { id },
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/dialog_version`,
            fetchOptions(getState(), 'PATCH', { dialog_version_id: id }),
          ),
      },
    );
  };

export function showAgeGateIfNeeded(
  userProfile: UserProfile,
  dispatch: Dispatch,
) {
  let status = userProfile.birthday_status || AgeGateStatus.Success;

  if (status !== AgeGateStatus.Success) {
    dispatch(
      setActiveDialog({
        type: Dialogs.AgeGate,
        status,
      }),
    );
  }
}

export const getCoreDescription =
  (): DA<CoreDescription> => async (dispatch, getState) => {
    return apiAction<A.GetCoreDescription>(
      AsyncActionTypes.GetCoreDescription,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/core_description`,
            fetchOptions(getState(), 'GET'),
          ),
      },
    );
  };

export const updateBackstory =
  (backstory: string): DA<CoreDescription> =>
  async (dispatch, getState) => {
    return apiAction<A.UpdateBackstory>(
      AsyncActionTypes.UpdateBackstory,
      dispatch,
      { backstory },
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/core_description/backstory`,
            fetchOptions(getState(), 'POST', { backstory }),
          ),
      },
    );
  };

export const updateActLike =
  (actLike: ReplikaActLikeBehavior): DA<CoreDescription> =>
  async (dispatch, getState) => {
    return apiAction<A.UpdateActLike>(
      AsyncActionTypes.UpdateActLike,
      dispatch,
      { actLike },
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/core_description/act_like`,
            fetchOptions(getState(), 'POST', { act_like: actLike }),
          ),
      },
    );
  };
